import dynamic from "next/dynamic";
import React, { useState, useEffect, Suspense } from "react";
import Cookies from "js-cookie";
import { LicensedStores } from "@/components/LicensedStores";
import HomeBanner from "@/components/HomeBanner";
import ProductsHome from "@/components/ProductsHome";
import SuperFavHome from "@/components/SuperFavHome";
import { useRouter } from "next/router";

import { initalizeTractEvents } from "../analytics-utils/eventTracker";
import { useDispatch } from "react-redux";
import {
  CONST_BANNER,
  CONST_FEATURED,
  CONST_BOD,
  CONST_DEVICE_BRAND,
  CONST_TRENDING,
} from "@/utils/constants";
import SpotlightBanner from "@/components/SpotlightBanner";
import { fetchLocation } from "@/store/locationSlice";
import Head from "next/head";
import ProductSlider from "@/components/ai/ProductSlider";

//import SliderV1 from "@/components/SliderV1";

const Spinner = dynamic(() => import("@/components/spinner"));
// const HeroBanner = dynamic(() => import("@/components/HeroBanner"));
const Wrapper = dynamic(() => import("@/components/Wrapper"));
const NewInHome = dynamic(() => import("@/components/NewInHome"));
// const DevicesHome = dynamic(() => import("@/components/DevicesHome"));
const Devices = dynamic(() => import("@/components/Devices"));
const OneOfOneBanner = dynamic(() => import("@/components/OneOfOneBanner"));
const BestsellerHome = dynamic(() => import("@/components/BestsellerHome"));

export default function Home(props) {
  const router = useRouter();

  const [isloading, setIsloading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocation());
  }, [dispatch]);
  const changeLoadingStatus = (st) => {
    setIsloading(st);
  };


  return (
    <Suspense fallback={<Spinner />}>

      <Head>
        <title>
          Macmerise - Tell your story through our products and innovation | Buy
          Perfect gifts Online to celebrate moments.
        </title>
        <meta
          name="description"
          content="Explore Macmerise for AI-designed mobile accessories and officially licensed products, including unique skins, cases, and more. Elevate your lifestyle with exclusive designs and top-quality products today!"
        />
      </Head>
      <main className="overflow-x-hidden">
        <HomeBanner bannArray={props.bannArray} />
        <Wrapper>
          <SuperFavHome supfavarray={props.supfavarray} />
          <NewInHome bestSeller={props.bestseller} />
        </Wrapper>
        <SpotlightBanner bannArray={props.bannArray} />
        <Wrapper>
          <Devices
            changeLoadingStatus={changeLoadingStatus}
            devicebrand={props.devicebrand}
          />

          <ProductsHome bannArray={props.bannArray} />
          {/* AI */}
          <ProductSlider />
        </Wrapper>
        <OneOfOneBanner bannArray={props.bannArray} />
        <Wrapper>
          <BestsellerHome bestSeller={props.bestseller} />
          <LicensedStores />
        </Wrapper>
        {isloading ? (
          <div className="flex w-full flex-wrap justify-center">
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 w-full xs:gap-[0.1rem] sm:gap-[0.1rem] md:gap-5 lg:gap-5 gap-5 my-10 md:px-0">
              <Spinner />
            </div>
          </div>
        ) : (
          ""
        )}
      </main>
      </Suspense>
  );
}

  export async function getServerSideProps(context) {
    const { req } = context;
    const token = req.cookies.mmtoken;
  
    const config = token
      ? { headers: { Authorization: token } }
      : { headers: { "Content-Type": "application/json", Accept: "application/json" } };
  
    try {
      const [bannerRes, trendingRes, brandRes, superfavRes] = await Promise.all([
        fetch(CONST_BANNER, { next: { revalidate: 60 } }),
        fetch(CONST_FEATURED, config),
        fetch(CONST_DEVICE_BRAND, { next: { revalidate: 60 } }),
        fetch(CONST_TRENDING, { next: { revalidate: 60 } }),
      ]);
  
      const [banner, trending, devicebrand, superfav] = await Promise.all([
        bannerRes.json(),
        trendingRes.json(),
        brandRes.json(),
        superfavRes.json(),
      ]);
  
      return {
        props: {
          bannArray: banner.data,
          bestseller: trending.data,
          devicebrand: devicebrand.data,
          supfavarray: superfav.data,
        },
      };
    } catch (error) {
      console.error(error);
      return { props: { bannArray: [], bestseller: [], devicebrand: [], supfavarray: [] } };
    }
  }
  
