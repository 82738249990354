import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper/modules";
import { Pagination, Navigation, Thumbs, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { CONST_BOD } from "@/utils/constants";
import { Spinner } from "@material-tailwind/react";
export const LicensedStores = () => {
  // SwiperCore.use([Pagination, Autoplay]);

  const [swiper, setSwiper] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [brands, setBrands] = useState([]);

  const GetLicensedStores = async () => {
    try {
      const ls = await fetch(CONST_BOD + "?type=brand");
      const res = await ls.json();

      setBrands(() => res.data);
    } catch (ex) {
      console.log(ex.message);
    }
  };
  useEffect(() => {
    GetLicensedStores();
  }, []);
  return (
    <>
      <div className="xs:my-[60px] sm:my-[60px] md:my-[100px] lg:my-[100px] ">
        <div className="text-center max-w-[800px] mx-auto xs:mb-[25px] sm:mb-[25px] md:mb-[40px] lg:mb-[40px]">
          <div className="xs:text-[24px] sm:text-[24px] md:text-[34px] lg:text-[34px] text-brand-charcoal avenir-black uppercase mb-[6px] font-bold">
            Licensed Stores
          </div>
          <div className="text-[16px] avenir-roman text-brand-charcoal">
            Official fandom merchandise for True Fans
          </div>
        </div>
        {brands?.length > 1 ? (
          <div className="w-full max-w-[1450px] mx-auto common-slider licensed-store">
            <Swiper
              slidesPerView={5}
              // slidesPerGroup={3}
              freeMode={true}
              grabCursor={true}
              spaceBetween={20}
              keyboard={true}
              loop={true}
              autoplay={{
                delay: 2500,
                pauseOnMouseEnter: true,
              }}
              direction="horizontal"
              mousewheel={{
                thresholdTime: 500,
                forceToAxis: true,
              }}
              // pagination={{
              //   clickable: false,
              //   enabled: false,
              //   horizontalClass: "hidden",
              // }}
              pagination={true}
              breakpoints={{
                640: {
                  slidesPerView: 5,
                  slidesPerGroup: 3,
                },
                414: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                300: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
              }}
              modules={[Pagination, Thumbs, Navigation, Mousewheel, Autoplay]}
              onSwiper={setSwiper}
              onSlideChange={(swiper) => {
                setSlideIndex(swiper.activeIndex);
              }}
              className="flex justify-center flex-row items-center relative"
            >
              {brands?.map((item, i) => {
                return (
                  <SwiperSlide key={i}>
                    <Link
                      data-component="LicensedStores"
                      href={`/p/${item.handle}` ?? "#"}
                      className="transform overflow-hidden bg-brand-uniwhite duration-200 hover:scale-105 cursor-pointer"
                    >
                      <Image
                        unoptimized
                        width={355}
                        height={200}
                        src={item?.image || "#"}
                        alt={item.title}
                        className="mx-auto"
                      />
                      <div className="p-4 text-brand-charcoal/[0.9]">
                        <h2 className="text-[12px] uppercase font-[800] text-center avenir-black">
                          {item.title}
                        </h2>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
