import React from "react";
import { InView } from "@/components/ui/in-view";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";

/**
 * Renders an image component with an enhanced aesthetic dissolve in-out animation effect.
 * @param {import('next/image').ImageProps & { containerProps?: React.HTMLProps<HTMLDivElement> }} props - The props for the InViewImage.
 * @returns {JSX.Element} The rendered InViewImage with enhanced dissolve effect.
 */
export function InViewImage({
  src,
  alt,
  className,
  style,
  containerProps,
  unoptimized, // omit the unoptimized prop
  ...props
}) {
  const enhancedDissolveVariants = {
    hidden: {
      opacity: 0,
      scale: 1.05,
      filter: "blur(20px) brightness(0.8)",
    },
    visible: {
      opacity: 1,
      scale: 1,
      filter: "blur(0px) brightness(1)",
      transition: {
        duration: 1.2,
        ease: [0.6, -0.05, 0.01, 0.99], // Custom easing function for a more dynamic feel
      },
    },
  };

  return (
    <InView
      viewOptions={{
        once: true,
        margin: "0px 0px -100px 0px",
        amount: "some",
      }}
      variants={{
        hidden: {
          opacity: 0.3,
        },
        visible: {
          opacity: 1,
          // transition: {
          //   staggerChildren: 0.09,
          // },
        },
      }}
      className={cn(
        "relative aspect-card overflow-hidden",
        containerProps?.className
      )}
    >
      <motion.div
        variants={enhancedDissolveVariants}
        // {...containerProps}
        // className={cn(
        //   "relative aspect-card overflow-hidden",
        //   containerProps?.className
        // )}
      >
        <motion.img
          src={src}
          alt={alt}
          className={cn("size-full object-cover", className)} // Changed to object-cover for better aesthetics
          style={{
            ...style,
            // transform: "scale(1.1)", // Slight zoom effect
            transition: "transform 0.3s ease-out",
          }}
          whileHover={{
            scale: 1.15,
            transition: { duration: 0.3 },
          }}
          {...props}
        />
      </motion.div>
    </InView>
  );
}
