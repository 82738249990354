import { InViewImage } from "@/components/InViewImage";
import { Button } from "@/components/ui/button";
import { products } from "@/lib/ai";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Thumbs, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
// import "swiper/css/pagination";
import "swiper/css/navigation"; // Import Swiper navigation CSS

const ProductSlider = () => {
  return (
    <>
      <div className="xs:mt-[60px] sm:mt-[60px] md:mt-[100px] lg:mt-[100px]">
        <div className="text-center max-w-[800px] mx-auto xs:mb-[25px] sm:mb-[25px] md:mb-[40px] lg:mb-[40px]">
          <div className="xs:text-[24px] sm:text-[24px] md:text-[34px] lg:text-[34px] text-brand-charcoal avenir-black uppercase mb-[6px] font-bold">
            Generate with AI
          </div>
          <div className="text-[16px] avenir-roman text-brand-charcoal">
            Can't find something you love? Generate with AI
          </div>
        </div>

        {products?.length > 1 ? (
          <div>
            <Swiper
              slidesPerView={4}
              slidesPerGroup={2}
              grabCursor={true}
              spaceBetween={20}
              keyboard={true}
              autoplay={false}
              direction="horizontal"
              mousewheel={{
                thresholdTime: 500,
                forceToAxis: true,
              }}
              pagination={{
                clickable: true,
                enabled: true,
              }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }} // Navigation configuration
              breakpoints={{
                640: {
                  slidesPerView: 4,
                },
                414: {
                  slidesPerView: 2,
                },
                300: {
                  slidesPerView: 2,
                },
              }}
              style={{
                //@ts-ignore
                "--swiper-pagination-color": "#FE5C46",
                "--swiper-pagination-bullet-inactive-color": "#d0d0d0",
                "--swiper-pagination-bullet-inactive-opacity": "1",
                // '--swiper-pagination-bullet-size': '16px',
                "--swiper-pagination-bullet-border-radius": "0px",
                "--swiper-pagination-bullet-horizontal-gap":
                  "var(--swiper-bullet-gap, 0px)",
                "--swiper-pagination-bullet-width":
                  "var(--swiper-bullet-width, 36px)",
                "--swiper-pagination-bullet-height":
                  "var(--swiper-bullet-height, 3px)",
              }}
              wrapperClass="pb-[30px] md:pb-[50px]"
              modules={[Pagination, Thumbs, Navigation, Mousewheel]}
              className="flex justify-center flex-row items-center relative flex-1"
            >
              {products.map((obj, i) => (
                <SwiperSlide key={i}>
                  <AiProductCard key={i} product={obj} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export const AiProductCard = ({ product, titleClass, ...props }) => {
  return (
    <Link
      href={`/ai/products/${product.slug.replace("/", "")}`}
      className="overflow-hidden bg-white-- cursor-pointer"
    >
      <div className="overflow-hidden xs:rounded-[5px] sm:rounded-[5px] md:rounded-[8px] lg:rounded-[8px]">
        <InViewImage
          unoptimized
          width={410}
          height={570}
          src={product.image}
          alt={product.name}
          className="w-full transition-transform duration-500 transform-gpu hover:scale-125"
        />
      </div>
      <div className="pt-2">
        <h2
          className={cn(
            "truncate-text text-[15px] avenir-black-- font-medium justify-center text-left text-[#0f0f0f]",
            titleClass
          )}
        >
          {product.name}
        </h2>
      </div>
    </Link>
  );
};

export default ProductSlider;
